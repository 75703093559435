import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonAtom from 'components/atoms/Buttons/Button';
import ButtonPrevious from 'components/atoms/Buttons/Previous';

import style from 'assets/stylesheets/molecules/Wizards/Default';

const AddMetadataStep = ({ step, data, children, isValidated, handleNextStep, handlePreviousStep, nextStepText, clientUpload, correctFiles, malwareData, apiError, timeoutError }) => {
    const { t } = useTranslation();

    const isDisableExternal = !(isValidated && correctFiles?.length > 0 && malwareData.length === 0 && !apiError && !timeoutError);
    const isDisableInternal = !isValidated || malwareData.length > 0 || apiError || timeoutError;
    const disable = clientUpload ? isDisableExternal : isDisableInternal;

    return (
        <form>
            <fieldset className={style.wizard__form__fieldset}>{children}</fieldset>
            <fieldset className={`${style.wizard__form__fieldset} ${style.wizard__form__fieldset__buttons}`}>
                {step !== 1 && (
                    <ButtonPrevious onClick={() => handlePreviousStep(data)}>{t('csp.previousStep')}</ButtonPrevious>
                )}
                <ButtonAtom.Submit onClick={() => handleNextStep(data)} isDisabled={disable} isRight>
                    {nextStepText}
                </ButtonAtom.Submit>
            </fieldset>
        </form>
    );
};

const { number, object, bool, func, string } = PropTypes;

AddMetadataStep.propTypes = {
    step: number,
    data: object,
    isValidated: bool,
    nextStep: bool,
    handleNextStep: func,
    handlePreviousStep: func,
    nextStepText: string,
};

export default AddMetadataStep;
