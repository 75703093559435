import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { addRequestUpload } from 'src/redux/actions';
import ButtonPrevious from 'components/atoms/Buttons/Previous';
import ButtonAtom from 'components/atoms/Buttons/Button';
import CheckboxAtom from 'components/atoms/Checkbox/Checkbox';
import style from 'assets/stylesheets/molecules/Wizards/Default';
import UserBlockMolecule from 'components/molecules/Users/UserBlock';
import InputSearchAtom from 'components/atoms/Inputs/Search';
import { retrievePortalAccountUsers } from 'services/addPortalAccountApiService';
import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';
import StackAtom from 'components/atoms/Stack/Stack';
import NotificationAtom from 'components/atoms/Notifications/Notification';

const AddSummaryStep = ({
    data,
    handleRetry,
    handleUploadOnboarding,
    handleRetryOnboarding,
    handleSendNotifications,
    handlePreviousStep,
    sendNotifications,
    eventTrigeredFrom,
    children,
    failed,
    submitLabel,
    showNotifyCheckbox,
    showPrevious,
    isValidated,
    clientId,
    isEditMode,
    paramData,
    clientUpload,
    correctRequestFileWithKey
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [submitted, setSubmit] = useState(false);

    const [usersList, setUsersList] = useState([]);
    const [selectedUsersList, setSelectedUsersList] = useState([]);

    const [userCount, setUserCount] = useState(0);
    const [isSearchMode, setIsSearchMode] = useState(false);

    const [isSelectAll, setIsSelectAll] = useState(true);

    const showUsers = (notifyUsers) => {
        if (submitted) {
            handleSendNotifications(data, notifyUsers);
        }
        setShowList(notifyUsers);
    };

    useEffect(() => {
        if (!isEditMode && !clientUpload && clientId) {
            retrievePortalAccountUsers({ clientAccountId: clientId }).then((users) => {
                const activeUsers = [...users?.activeUsers?.filter(item=>item.role === "GENERAL")];

                const userSelectionMapping = activeUsers?.map((user) => ({
                    ...user,
                    selected: true,
                }));
                setSelectedUsersList(userSelectionMapping);
                //setUsersList(userSelectionMapping)
                setUserCount(userSelectionMapping.length);
            });
        }
    }, [clientId]);

    // this function is specific to document request sub selection need to be extracted and loosely couple the handle upload functionality
    const handleDocumentUpload = (data, type) => {
        const { metadata, sendNotifications } = paramData;
        const filterSelectedUsers = selectedUsersList?.filter((user) => user.selected);
        const notifyUserIdCollection = filterSelectedUsers?.map((selectedUser) => selectedUser.userId); // this fix will remove the nulls
        const params = {
            sendNotifications: showList || false, // this fix will say the when the notifications are required.
            title: metadata.title,
            descriptionWhatAndHow: metadata.descriptionWhatAndHow || null,
            descriptionWhy: metadata.descriptionWhy || null,
            clientAccountIds: [clientId],
            files: metadata.files || null,
            // isSensitive: metadata.isSensitive,
            isUrgent: metadata.isUrgent,
            uploadStatus: type,
            notifyUserIds: !isEditMode && notifyUserIdCollection,
        };

        dispatch(addRequestUpload({ params, isEditMode, documentId: metadata.documentId, clientUpload, correctRequestFileWithKey }));
    };

    const userIndex = (user) => {
        const indexArray = selectedUsersList.map((selectedUser, index) => {
            if (user.email === selectedUser.email) return index;
            return -1;
        });
        const [indexValue] = indexArray.filter((item) => item >= 0);

        return indexValue;
    };
    const toggleUsers = (toggledUser) => {
        const objCheck = { ...toggledUser, selected: !toggledUser.selected };
        const selectedIndex = !isSearchMode ? selectedUsersList.indexOf(toggledUser) : userIndex(toggledUser);
        if (isSearchMode) {
            usersList.splice(usersList.indexOf(toggledUser), 1, objCheck);
            setUsersList([...usersList]);
        }
        selectedUsersList.splice(selectedIndex, 1, objCheck);
        setSelectedUsersList([...selectedUsersList]);
        const usersLength = selectedUsersList.filter((user) => user.selected).length;
        setUserCount(usersLength);
        setIsSelectAll(usersLength === selectedUsersList.length);
    };

    const renderUsers = (listUsers) => {
        const usersMapping = listUsers?.map((value, index) => {
            return (
                <div key={value.userId + index} className="d-flex justify-content-between col">
                    <CheckboxAtom
                        parentcustomClass={style.checkbox}
                        checked={value.selected}
                        onChange={() => toggleUsers(value)}
                        hasHiddenLabel
                    />
                    <UserBlockMolecule user={value} notifyStyles={style.userbox} isNotifyBox={true} showActions = { false }>
                        <div>{`${t('csp.email')}: ${value.email}`}</div>
                    </UserBlockMolecule>
                </div>
            );
        });
        const temp = usersMapping.length > 0 ? usersMapping : [];
        return temp.length > 0 ? (
            <ul className="list-with-divider col-9">{temp}</ul>
        ) : (
            <div style={{ 'line- height': '48px', padding: '20px', position: 'absolute' }} className="offset-2">
                {isSearchMode ? 'No Results Founds' : <LoadingIndicatorAtom />}
            </div>
        );
    };
    const filterUsers = (criteria) => {
        let filteredUsers = [];
        for (var user of selectedUsersList) {
            const fullname = `${user.givenName} ${user.familyName}`;
            if (user.email?.toLowerCase().includes(criteria)) {
                filteredUsers.push(user);
                continue;
            }
            if (user.familyName?.toLowerCase().includes(criteria)) {
                filteredUsers.push(user);
                continue;
            }

            if (user.givenName?.toLowerCase().includes(criteria)) {
                filteredUsers.push(user);
                continue;
            }
            if (fullname.toLowerCase().includes(criteria)) {
                filteredUsers.push(user);
                continue;
            }
        }
        return filteredUsers;
    };
    const handleSearch = (event) => {
        event.stopPropagation();
        const searchCriteria = event.target.value;
        setIsSearchMode(searchCriteria.length > 0);

        if (searchCriteria.length > 0 && selectedUsersList.length > 0) {
            setUsersList(filterUsers(searchCriteria.toLowerCase()));
        } else {
            setSelectedUsersList(selectedUsersList);
        }
    };

    const handleNotifyAll = (notifyAll) => {
        let allUser = selectedUsersList;
        let selectedUser = [];
        if (notifyAll) {
            allUser.map((el) => {
                const selectall = { ...el, selected: true };
                selectedUser.push(selectall);
            });
            setSelectedUsersList(selectedUser);
            setUserCount(selectedUser.length);
            setIsSelectAll(true);
        } else {
            allUser.map((el) => {
                const selectall = { ...el, selected: false };
                selectedUser.push(selectall);
            });
            setSelectedUsersList(selectedUser);
            setUserCount(0);
            setIsSelectAll(false);
        }
    };
    // const usersToShow = !isSearchMode ? selectedUsersList : usersList
    const renderContacts = () => {
        const userstoShow = isSearchMode ? usersList : selectedUsersList;
        return (
            <div className={`${style.notifycontainer} container mt-5 ml-0`}>
                <div className={`row ${style.notifycontainer__info}`}>
                    <div className="col p-0">
                        <div className={`${style.notifycontainer__info__border} d-flex justify-content-between`}>
                            <div>
                                <CheckboxAtom
                                    label={'Notify All Contacts'}
                                    onChange={(event) => handleNotifyAll(event.target.checked)}
                                    checked={isSelectAll}
                                />
                            </div>
                            <div>
                                <p>
                                    {' '}
                                    {userCount === 0
                                        ? 'Please select at least one contact to submit'
                                        : `Selected Contacts(${userCount})`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-3" key="searchBox">
                    <div className="col p-0">
                        <InputSearchAtom placeholder={t('csp.searchUsers')} onChange={(event) => handleSearch(event)} />
                    </div>
                </div>
                <div className="row" key="contactListsToNotify">
                    {renderUsers(userstoShow)}
                </div>
            </div>
        );
    };

    const handleSwitch = (origin, type) => {
        switch (origin) {
            case 'DocumentRequest':
                handleDocumentUpload(data, type);
                break;
            case 'onboardingEdit':
                handleUploadOnboarding();
                break;
            default:
                handleUpload(data, type);
        }
    };

    return (
        <form>
            <fieldset className={style.wizard__form__fieldset}>
                {children}
                {clientUpload && <NotificationAtom type="info">{t('csp.uponClientDocumentSubmit')}</NotificationAtom>}
                {!!showNotifyCheckbox && !clientUpload && (
                    <CheckboxAtom
                        label={t('csp.notifyByEmail')}
                        checked={showList}
                        onChange={(event) => showUsers(event.target.checked)}
                    />
                )}
                {!isEditMode && showList && renderContacts()}
            </fieldset>
            <fieldset className={`${style.wizard__form__fieldset} ${style.wizard__form__fieldset__buttons}`}>
                {!!showPrevious && (
                    <ButtonPrevious onClick={() => handlePreviousStep(data)} ghost="true">
                        {t('csp.previousStep')}
                    </ButtonPrevious>
                )}

                <div className={style.wizard__form__fieldset__buttons__checkbox}>
                    {failed ? (
                        <div className={style.wizard__form__fieldset__buttons__double}>
                            <ButtonAtom
                                onClick={
                                    eventTrigeredFrom === 'onboardingEdit'
                                        ? () => handleRetryOnboarding()
                                        : () => handleRetry()
                                }
                                disabled={!failed}>
                                {t('csp.tryAgain')}
                            </ButtonAtom>
                        </div>
                    ) : (
                        <>
                            <StackAtom nowrap justify="flex-end" customClasses={style.docinfo__btn}>
                                {clientUpload && (
                                    <ButtonAtom.Submit
                                        buttonStyle="secondary"
                                        onClick={() => handleSwitch(eventTrigeredFrom, 'save')}>
                                        {t('csp.saveForLater')}
                                    </ButtonAtom.Submit>
                                )}
                                <ButtonAtom.Submit
                                    onClick={() => handleSwitch(eventTrigeredFrom, 'submit')}
                                    isDisabled={isValidated && !isEditMode ? showList && userCount === 0 : !isValidated}
                                    isRight>
                                    {submitLabel}
                                </ButtonAtom.Submit>
                            </StackAtom>
                        </>
                    )}
                </div>
            </fieldset>
        </form>
    );
};

AddSummaryStep.defaultProps = {
    step: 1,
    data: {},
    handlePreviousStep: () => {},
    handleSendNotifications: () => {},
    handleUploadOnboarding: () => {},
    handleDocumentUpload: () => {},
    handleRetryOnboarding: () => {},
    sendNotifications: true,
    failed: false,
    eventTrigeredFrom: '',
    submitLabel: 'Submit',
    showNotifyCheckbox: true,
    showPrevious: true,
    isValidated: true,
};

const { number, object, func, bool, string } = PropTypes;

AddSummaryStep.propTypes = {
    step: number,
    data: object,
    handlePreviousStep: func,
    handleUploadOnboarding: func,
    handleDocumentUpload: func,
    handleRetryOnboarding: func,
    handleSendNotifications: func,
    sendNotifications: bool,
    eventTrigeredFrom: string,
    failed: bool,
    submitLabel: string,
    showNotifyCheckbox: bool,
    showPrevious: bool,
    isValidated: bool,
    isEditMode: bool,
};

export default AddSummaryStep;
