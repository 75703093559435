// Base
import React from 'react';
import PropTypes from 'prop-types';

// Style
import style from 'assets/stylesheets/atoms/Inputs/LabelBefore';

const LabelBeforeWithChildren = ({
    required,
    label,
    customIconClass,
    parentCustomClass,
    children,
    ...attributes
}) => {

    return (
        <div className={ `input ${ style.input } ${ parentCustomClass } ${ style.input__label__before } ` }>
            <label className={ `${ style.input__label } ${style.input__textarea__label}` }>
                <span className="input__label__content">
                    { label }
                </span>
                { required &&
                    <i className={ `${ customIconClass ? customIconClass : ''} icon icon--star-filled ${ style['icon--star-filled'] }` }>
                        <span>&#42;</span>
                    </i>
                }
            </label>
            { children }
        </div>
    );
};

LabelBeforeWithChildren.defaultProps = {
    required: false,
    label: '',
    customIconClass: '',
    parentCustomClass: '',
    children: null
};

const { string, bool, node } = PropTypes;

LabelBeforeWithChildren.propTypes = {
    required: bool,
    label: string,
    customIconClass: string,
    parentCustomClass: string,
    children: node,
};

export default LabelBeforeWithChildren;