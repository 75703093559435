// Base
import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

// Style
import style from 'assets/stylesheets/atoms/Initials/Default';

const Initials = ({ isDisabled, children, customClass }) => {
    const classes = classNames('initials', style.initials, customClass, {
        [style['initials--disabled']]: isDisabled,
    });

    return (
        <figure className={classes} data-analytics-notrack="username">
            <div className={style.initials__text} data-analytics-notrack="username">{children}</div>
        </figure>
    );
};

const { bool, node, string } = PropTypes;

Initials.propTypes = {
    /** Is user disabled */
    isDisabled: bool,
    customClass: string,
    children: node,
};

Initials.defaultProps = {
    isDisabled: false,
    customClass: '',
    children: null,
};

export default Initials;
