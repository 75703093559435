// Base
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

// Style
import style from 'assets/stylesheets/templates/FullPageOverlay';

// Components
import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';
import HeadingAtom from 'components/atoms/Headings/Default';
import CancelButton from 'components/atoms/Buttons/Cancel';

const FullPageOverlay = ({ pageTitle = '', subPageTitle, isKYC, children, isKYCFlow, clientId }) => {
    return (
        <div className={style.fullpage}>
            <Suspense fallback={<LoadingIndicatorAtom />}>
                <header className={`${style.fullpage__header} ${isKYC ? 'col-11 pl-0 pr-0' : ''}`}>
                    <HeadingAtom tag="h1">{pageTitle}</HeadingAtom>
                    <CancelButton isKYCFlow={isKYCFlow} clientId={clientId} />
                </header>
                <main className={style.fullpage__main}>
                    {subPageTitle && <small className={'caps-subtitle'}>{subPageTitle}</small>}
                    {children}
                </main>
            </Suspense>
        </div>
    );
};

const { string } = PropTypes;

FullPageOverlay.propTypes = {
    pageTitle: string,
};

export default FullPageOverlay;
