// Base
import React from 'react';

// Style
import style from 'assets/stylesheets/atoms/Checkbox/Checkbox';

const CheckboxDefault = ({
    label,
    hasHiddenLabel,
    parentcustomClass = '',
    isKYC,
    labelCustomStyles,
    ...attributes
}) => {
    const labelStyles = `${hasHiddenLabel ? 'screenreader' : ''} ${isKYC ? labelCustomStyles : ''}`;

    return (
        <label className={`checkbox ${style.checkbox} ${parentcustomClass}`}>
            <input type="checkbox" {...attributes} />
            <div className={style.container}>
                <svg className={style.svg} viewBox="-4 -4 39 39" aria-hidden="true" focusable="false">
                    <rect
                        className={style.background}
                        width="35"
                        height="35"
                        x="-2"
                        y="-2"
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="3"
                        rx="0"
                        ry="0"></rect>
                    <polyline
                        className={style.mark}
                        points="8,14 14,20 24,8"
                        stroke="transparent"
                        strokeWidth="4"
                        fill="none"></polyline>
                </svg>
            </div>

            <span className={labelStyles}>{label}</span>
        </label>
    );
};

export default CheckboxDefault;
