import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputLabelBeforeAtom from 'components/atoms/Inputs/LabelBefore';
import TextareaAtom from 'components/atoms/Inputs/Textarea';
import LabelBeforeWithChildrenAtom from 'components/atoms/Inputs/LabelBeforeWithChildren';
import FileUploaderExternalMolecule from 'components/molecules/External/FileUploader';
import CheckboxAtom from 'components/atoms/Checkbox/Checkbox';

import style from 'assets/stylesheets/molecules/Overview/MetaData';

const RequestMetadata = ({ metadata, clientName, handleMetadata, handleDrop, clientUpload, correctFiles, malwareData, apiError, timeoutError }) => {
    const { t } = useTranslation();
    return (
        <div className={style.metadata}>
            <ul className={style.metadata__list}>
                <li id={`metadata-${metadata.clientId}`} className={style.metadata__list__item} key={metadata.clientId}>
                    <InputLabelBeforeAtom
                        type="text"
                        required={false}
                        readOnly={true}
                        label={t('csp.clientMandate')}
                        id={`metadata-clientAccount-${clientName}`}
                        defaultValue={clientName}
                    />
                    <InputLabelBeforeAtom
                        type="text"
                        required={true}
                        maxLength={100}
                        label={t('csp.title')}
                        placeholder={
                            clientUpload ? t('csp.client.title.placeholder') : t('csp.requested.title.placeholder')
                        }
                        id={`metadata-title-${metadata.title}`}
                        defaultValue={metadata.title}
                        onChange={(event) => handleMetadata(event.target.value, 'title')}
                    />

                    <TextareaAtom
                        type="text"
                        required={clientUpload}
                        label={t('csp.description')}
                        placeholder={
                            clientUpload
                                ? t('csp.client.description.placeholder')
                                : t('csp.requested.description.placeholder')
                        }
                        id={`metadata-description-${metadata.description}`}
                        defaultValue={metadata.descriptionWhatAndHow}
                        hasLabelBefore={true}
                        maxLength={500}
                        onChange={(event) => handleMetadata(event.target.value, 'descriptionWhatAndHow')}
                    />
                    {!clientUpload && (
                        <TextareaAtom
                            type="text"
                            required={false}
                            label={t('csp.importance')}
                            placeholder={t('csp.requested.importance.placeholder')}
                            id={`metadata-importance-${metadata.importance}`}
                            defaultValue={metadata.descriptionWhy}
                            hasLabelBefore={true}
                            maxLength={500}
                            onChange={(event) => handleMetadata(event.target.value, 'descriptionWhy')}
                        />
                    )}
                    {clientUpload ? (
                        <LabelBeforeWithChildrenAtom label={''}>
                            <FileUploaderExternalMolecule
                                droppedFiles={metadata.files}
                                handleDrop={handleDrop}
                                allowMultiple={true}
                                clientUpload={clientUpload}
                                isDocument='RequestDocument'
                                scannedDocuments={correctFiles}
                                malwareData={malwareData}
                                timeoutError={timeoutError}
                                apiError={apiError}
                            />
                        </LabelBeforeWithChildrenAtom>
                    ) : (
                        <LabelBeforeWithChildrenAtom label={t('csp.emptyForm')}>
                            <FileUploaderExternalMolecule
                                droppedFiles={metadata.files}
                                handleDrop={handleDrop}
                                allowMultiple={false}
                                isDocument='RequestDocument'
                                clientUpload={clientUpload}
                                scannedDocuments={correctFiles}
                                malwareData={malwareData}
                                timeoutError={timeoutError}
                                apiError={apiError}
                            />
                        </LabelBeforeWithChildrenAtom>
                    )}
                    <LabelBeforeWithChildrenAtom label={t('csp.urgency')}>
                        <CheckboxAtom
                            label={t('csp.requested.urgency.placeholder')}
                            checked={metadata.isUrgent}
                            onChange={(event) => handleMetadata(event.target.checked, 'isUrgent')}
                        />
                    </LabelBeforeWithChildrenAtom>
                    {/* <LabelBeforeWithChildrenAtom label={t('csp.security')}> */}
                        {/* <CheckboxAtom
                            label={t('csp.sensitive')}
                            checked={metadata.isSensitive}
                            onChange={(event) => handleMetadata(event.target.checked, 'isSensitive')}
                        /> */}
                    {/* </LabelBeforeWithChildrenAtom> */}
                </li>
            </ul>
        </div>
    );
};

RequestMetadata.defaultProps = {
    metadata: {},
    clientName: '',
    handleMetadata: () => {},
    handleDrop: () => {},
};

const { string, object } = PropTypes;

RequestMetadata.propTypes = {
    metadata: object,
    clientName: string,
    handleMetadata: PropTypes.func,
    handleDrop: PropTypes.func,
};

export default RequestMetadata;
