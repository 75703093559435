import React from 'react';

export const AccountSelection = React.lazy(() => import('./AccountSelection'));
export const AddKYCDocument = React.lazy(() => import('./AddKYCDocument'));
export const RequestBulkKYC = React.lazy(() => import('./RequestBulkKYC'));
export const AddDocument = React.lazy(() => import('./AddDocument'));
export const EditDocument = React.lazy(() => import('./EditDocument'));
export const AddRequest = React.lazy(() => import('./AddRequest'));
export const AddPortalAccount = React.lazy(() => import('./AddPortalAccount'));
export const AssetMix = React.lazy(() => import('./AssetMix'));
export const AssetMixNew = React.lazy(() => import('./AssetMixNew'));
export const DashboardAdmin = React.lazy(() => import('./DashboardAdmin'));
export const Disclaimer = React.lazy(() => import('./Disclaimer'));
export const DocumentInformation = React.lazy(() => import('./DocumentInformation'));
export const DocumentStore = React.lazy(() => import('./DocumentStore'));
export const DocumentManagement = React.lazy(() => import('./DocumentManagement'));
export const Dashboard = React.lazy(() => import('./Dashboard'));
export const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
export const ResetPassword = React.lazy(() => import('./ResetPassword'));
export const FundsList = React.lazy(() => import('./FundsList'));
export const FundDetails = React.lazy(() => import('./FundDetails'));
export const LdiTool = React.lazy(() => import('./LdiTool'));
export const Login = React.lazy(() => import('./Login'));
export const NoClients = React.lazy(() => import('./NoClients'));
export const NotFound = React.lazy(() => import('./NotFound'));
export const NotificationManagement = React.lazy(() => import('./NotificationManagement'));
export const Performance = React.lazy(() => import('./Performance'));
export const PortalAccounts = React.lazy(() => import('./PortalAccounts'));
export const PortalAccountDetail = React.lazy(() => import('./PortalAccountDetail'));
// export const PreferenceCentre = React.lazy(() => import('./PreferenceCentre'));
export const UserDetail = React.lazy(() => import('./UserDetail'));
export const UserManagement = React.lazy(() => import('./UserManagement'));
export const SamlLogin = React.lazy(() => import('./SamlLogin'));
export const Security = React.lazy(() => import('./Security'));
export const Settings = React.lazy(() => import('./Settings'));
export const StatementOfChanges = React.lazy(() => import('./StatementOfChanges'));
export const Transactions = React.lazy(() => import('./Transactions'));
export const Process = React.lazy(() => import('./Process'));
export const CoverageRatio = React.lazy(() => import('./CoverageRatio'));
export const EditProcess = React.lazy(() => import('./EditProcess'));
export const DownloadPage = React.lazy(() => import('./Download'));
export const CollateralData = React.lazy(() => import('./CollateralData'));
export const WholesaleMasterlist = React.lazy(() => import('./WholesaleMasterlist'));
export const AltCreditDashboard = React.lazy(() => import('./AltCredit'));
export const ICDemoDashboard = React.lazy(() => import('./ICDemoDashboard'));
