import { all, takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as clientActions from '../../actions/clients';
import { addAnalyticsEvent, receiveSaleforceClientData, receiveSaleforceProspectData } from '../../actions/index';
import { genericErrorMessage } from './util';
import * as accountSelectionApiService from 'services/accountSelectionApiService';
import * as clientApiService from 'services/clientApiService';
import * as addPortalAccount from 'services/addPortalAccountApiService';
import { getLocales, getAudienceURL } from 'components/_helpers/localesHelper';
import { retrieveRelation } from 'src/services/relationApiService';
import { retrieveExternalUserRole } from 'src/services/userManagementApiService';

function* fetchPortalAccounts(action) {
    const state = action.payload.state;
    if (!state) {
        // Call fetch clients if data has not been loaded yet
        try {
            const clients = yield call(accountSelectionApiService.retrieveAccounts);
            yield put(clientActions.receiveClients(clients));
        } catch (error) {
            yield genericErrorMessage(error);
        }
    }
}

function* fetchPortalAccountWidgets(action) {
    const clientAccountId = action.payload.clientAccountId;
    try {
        const response = yield call(clientApiService.retrievePortalAccountWidgets, clientAccountId);
        yield put(clientActions.receiveClientWidgets(response.data));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* fetchSalesforceAccountDetailsClient() {
    try {
        const response = yield call(addPortalAccount.retrieveSalesForceAccounts, { type: 'client' });
        yield put(receiveSaleforceClientData(response));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}
function* fetchSalesforceAccountDetailsProspect() {
    try {
        const response = yield call(addPortalAccount.retrieveSalesForceAccounts, { type: 'prospect' });
        yield put(receiveSaleforceProspectData(response));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* updateWidgetEnabled(action) {
    const { clientAccountId, widgetCode, enabled, widgetProperty } = action.payload;

    try {
        yield call(clientApiService.updateWidgetEnabled, clientAccountId, widgetCode, enabled, widgetProperty);
        yield put(clientActions.setClientWidgetEnabled({ widgetCode, enabled }));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* updateWidgetInternalOnly(action) {
    const { clientAccountId, widgetCode, internalOnly } = action.payload;

    try {
        yield call(clientApiService.updateWidgetInternalOnly, clientAccountId, widgetCode, internalOnly);
        yield put(clientActions.setClientWidgetInternalOnly({ widgetCode, internalOnly }));
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

function* fetchCurrentClient(action) {
    const clientId = action.payload;

    if (!clientId) {
        return;
    }

    try {
        yield put(clientActions.setCurrentClientLoading(true));
        const currentClient = yield call(clientApiService.retrieveClient, clientId);
        const relationData = yield call(retrieveRelation, { clientId });

        if (currentClient) {
            const clientInfo = {
                ...currentClient,
                locale: getLocales(currentClient.country),
                audience: getAudienceURL(currentClient.country),
                relationData: relationData,
            };
            sessionStorage.setItem('currentClient', JSON.stringify(clientInfo));
            yield put(clientActions.setCurrentClient(clientInfo));
            // yield put(
            //     addAnalyticsEvent({
            //         eventName: 'clientAccountVisit',
            //         data: {
            //             accountType: currentClient.accountType,
            //             clientId: currentClient.id,
            //             clientName: currentClient.name,
            //             isAltis: currentClient.isAltis,
            //         },
            //     })
            // );
        }

        yield put(clientActions.setCurrentClientLoading(false));
    } catch (error) {
        yield put(clientActions.setCurrentClientLoading(false));

        if (!sessionStorage.getItem('auth')) return;

        if (error.message === 'Request failed with status code 401') {
            yield put(clientActions.setIsUnauthorised({ unauthorised: true }));
        } else {
            yield genericErrorMessage(error);
        }
    }
}

function* fetchEnabledWidgets(action) {
    const { clientAccountId, clientName, clientCountry } = action.payload;
    try {
        const userId = yield select((state) => state.auth.user.username);
        const response = yield call(clientApiService.retrieveEnabledWidgets, { clientAccountId: clientAccountId });
        const externalRole = yield call(retrieveExternalUserRole, { userId, portalAccountId: clientAccountId });
        yield put(clientActions.setDashboardWidgets({ widgetCodes: response.data, externalRole }));
        if (clientName) {
            // send client name and enabled widgets on dashboard to analytics
            // yield put(
            //     addAnalyticsEvent({
            //         eventName: 'dashboardVisited',
            //         data: {
            //             clientName,
            //             clientId: clientAccountId,
            //             widgets: response.data.filter((i) => !i.internlOnly).map((i) => i.widgetCode),
            //             clientCountry,
            //             externalRole: externalRole.role,
            //         },
            //     })
            // );
        }
    } catch (error) {
        yield genericErrorMessage(error);
    }
}

export default function* clientApiSagas() {
    yield all([
        takeEvery(`${actions.fetchPortalAccounts}`, fetchPortalAccounts),
        takeEvery(`${actions.fetchPortalAccountWidgets}`, fetchPortalAccountWidgets),
        takeEvery(`${actions.fetchSalesforceAccountDetailsClient}`, fetchSalesforceAccountDetailsClient),
        takeEvery(`${actions.fetchSalesforceAccountDetailsProspect}`, fetchSalesforceAccountDetailsProspect),
        takeEvery(`${actions.updateWidgetEnabled}`, updateWidgetEnabled),
        takeEvery(`${actions.updateWidgetInternalOnly}`, updateWidgetInternalOnly),
        takeLatest(`${actions.fetchEnabledWidgets}`, fetchEnabledWidgets),
        takeEvery(`${actions.fetchCurrentClient}`, fetchCurrentClient),
    ]);
}
