import React from 'react';

const DownloadIcon = () => (
    <svg width="24" height="24" viewBox="9 7 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 17H27L20 24L13 17H17V11H23V17ZM13 28V26H27V28H13Z"
            fill="currentColor"
        />
    </svg>
);

export default DownloadIcon;
