import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import InitialAtom from 'components/atoms/Initials/Default';
import HighlightedTextAtom from 'components/atoms/Text/HighlightedText';

import { contactName } from 'components/_helpers/contactName';

import style from 'assets/stylesheets/molecules/Users/UserBlock';
import ActionDropdownAtom from 'components/atoms/ActionDropdown/ActionDropdown';
const UserBlock = ({
    user,
    linkTo,
    actions,
    hasBorder,
    customClass,
    searchQuery,
    children,
    isNotifyBox,
    notifyStyles,
    retrieveUserActions,
    handleUserStatus,
    handleEditRoleModal,
    handleResendInvitation,
    clientId,
    isActive,
    showActions = true,
}) => {
    const name = contactName(user);
    let displayName = name;
    const components = {
        link: Link,
        block: 'div',
    };

    const ElementName = linkTo ? components.link : components.block;

    if (searchQuery) {
        displayName = <HighlightedTextAtom text={name} searchQuery={searchQuery}></HighlightedTextAtom>;
    }
    return (
        <ElementName
            data-test-id={'user-block'}
            data-analytics-notrack="username"
            className={`${style['user']} ${customClass} ${
                hasBorder ? style['user__block'] : style['user__list-item']
            } ${isNotifyBox && 'col-11 ml-3'}`}
            to={linkTo}
            title={name}>
            <InitialAtom isSmall  customClass={style['user__avatar']}>
                {user.givenName.charAt(0)}
                {user.familyName.charAt(0)}
            </InitialAtom>
            <div className={`${style['user__details']} ${isNotifyBox && style.notifyuserbox}`} data-analytics-notrack="username">
                <div data-analytics-notrack="username">{displayName}</div>
                {children}
            </div>
            {showActions &&
                <div className={style.user__button}>
                    <ActionDropdownAtom
                        loading={false}
                        toggleType="seemore"
                        retrieveUserActions={retrieveUserActions}
                        handleUserStatus={handleUserStatus}
                        handleEditRoleModal={handleEditRoleModal}
                        handleResendInvitation={handleResendInvitation}
                        user={user}
                        clientId={clientId}
                        isActive={isActive}
                    />
                </div>}
        </ElementName>
    );
};

const { object, string, node } = PropTypes;

UserBlock.propTypes = {
    user: object,
    linkTo: string,
    actions: node,
    customClass: string,
    searchQuery: string,
};

UserBlock.defaultProps = {
    user: {},
    linkTo: null,
    actions: null,
    customClass: '',
    searchQuery: null,
};

export default UserBlock;
