// Base
import React from 'react';

// Style
import style from 'assets/stylesheets/atoms/Inputs/LabelBefore';

export default class InputDefault extends React.Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        let input = document.getElementById(this.props.id);

        if (input.value.length > 0) {
            input.classList.add(style['input__type--filled']);
        } else {
            input.classList.remove(style['input__type--filled']);
        }
    }

    render() {
        const {
            nostyle,
            required,
            label,
            placeholder,
            customIconClass,
            parentcustomClass,
            customClassesLabel,
            customClassesInput,
            ...attributes
        } = this.props;

        return (
            <div
                className={`${parentcustomClass ? parentcustomClass + ' ' : ''}input ${style.input} ${
                    style.input__label__before
                }`}>
                <label className={`${style.input__label}${customClassesLabel ? ' ' + customClassesLabel : ''}`}>
                    <span className="input__label__content">{label}</span>
                    {required && (
                        <i
                            className={`${customIconClass ? customIconClass : ''} icon icon--star-filled ${
                                style['icon--star-filled']
                            }`}>
                            <span>&#42;</span>
                        </i>
                    )}
                </label>
                <input
                    {...attributes}
                    data-lpignore={true}
                    placeholder={placeholder}
                    onBlur={this.handleChange.bind(this)}
                    className={`${style.input__type}${customClassesInput ? ' ' + customClassesInput : ''}${
                        nostyle ? ' ' + style.input__nostyle : ''
                    }`}
                />
            </div>
        );
    }
}
