import React from 'react';

const FileIcon = ({ type, customClass = '' }) => (
    <div className={`file-icon ${customClass}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                {/* <path fill="#FFF" d="M3 0h12.94L21 5.06V24H3z" /> */}
                {/* <path fill="#E5DED9" d="M16 0l5 5v19H3V0h13zm-1 1H4v22h16V6h-5V1zm1 .4V5h3.6L16 1.4z" /> */}
                {(() => {
                    switch (type) {
                        case 'zip':
                        case 'application/zip':
                        case 'application/octet-stream':
                        case 'application/x-zip-compressed':
                        case 'multipart/x-zip':
                            return (
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14 2H6C4.89 2 4 2.89 4 4V20C4 21.1 4.89 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18.5 9H13V3.5L18.5 9ZM6.12 15.5L9.86 19.24L11.28 17.83L8.95 15.5L11.28 13.17L9.86 11.76L6.12 15.5ZM13.54 11.76L17.28 15.5L13.54 19.24L12.12 17.83L14.45 15.5L12.12 13.17L13.54 11.76Z"
                                    fill="currentColor"
                                />
                            );
                        case 'doc':
                        case 'application/msword':
                        case 'docx':
                        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            return (
                                <path
                                    d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM15.2 20H13.8L12 13.2L10.2 20H8.8L6.6 11H8.1L9.5 17.8L11.3 11H12.6L14.4 17.8L15.8 11H17.3L15.2 20ZM13 9V3.5L18.5 9H13Z"
                                    fill="currentColor"
                                />
                            );
                        case 'pps':
                        case 'ppsx':
                        case 'ppt':
                        case 'application/vnd.ms-powerpoint':
                        case 'pptx':
                        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                            return (
                                <path
                                    d="M12.6 12.3H10.6V15.5H12.7C13.3 15.5 13.6 15.3 13.9 15C14.2 14.7 14.3 14.4 14.3 13.9C14.3 13.4 14.2 13.1 13.9 12.8C13.6 12.5 13.2 12.3 12.6 12.3ZM14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM15.2 16C14.6 16.5 14.1 16.7 12.8 16.7H10.6V20H9V11H12.8C14.1 11 14.7 11.3 15.2 11.8C15.8 12.4 16 13 16 13.9C16 14.8 15.8 15.5 15.2 16ZM13 9V3.5L18.5 9H13Z"
                                    fill="currentColor"
                                />
                            );

                        case 'xls':
                        case 'application/vnd.ms-excel':
                        case 'xlsx':
                        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            return (
                                <path
                                    d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM15.8 20H14L12 16.6L10 20H8.2L11.1 15.5L8.2 11H10L12 14.4L14 11H15.8L12.9 15.5L15.8 20ZM13 9V3.5L18.5 9H13Z"
                                    fill="currentColor"
                                />
                            );
                        case 'pdf':
                        case 'application/pdf':
                            return (
                                <path
                                    d="M9 7H14.5L9 1.5V7ZM2 0H10L16 6V18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0V0ZM6.1 9.4C6.08 9.44 5.81 11.16 4 14.09C4 14.09 0.5 15.91 1.33 17.27C2 18.35 3.65 17.23 5.07 14.59C5.07 14.59 6.89 13.95 9.31 13.77C9.31 13.77 13.17 15.5 13.7 13.66C14.22 11.8 10.64 12.22 10 12.41C10 12.41 8 11.06 7.5 9.2C7.5 9.2 8.64 5.25 6.89 5.3C5.14 5.35 5.8 8.43 6.1 9.4ZM6.91 10.44C6.94 10.45 7.38 11.65 8.8 12.9C8.8 12.9 6.47 13.36 5.41 13.8C5.41 13.8 6.41 12.07 6.91 10.44ZM10.84 13.16C11.42 13 13.17 13.31 13.1 13.64C13.04 13.97 10.84 13.16 10.84 13.16ZM3.77 15C3.24 16.24 2.33 17 2.1 17C1.87 17 2.8 15.4 3.77 15ZM6.91 8.07C6.91 8 6.55 5.87 6.91 5.92C7.45 6 6.91 8 6.91 8.07Z"
                                    fill="currentColor"
                                />
                            );
                        case 'DOCUMENT_REQUEST':
                        default:
                            return null;
                    }
                }).call(this)}
            </g>
        </svg>
    </div>
);
export default FileIcon;
