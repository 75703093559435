import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

//Components
import NotificationAtom from 'components/atoms/Notifications/Notification';

import style from 'assets/stylesheets/molecules/Wizards/Default';

const AddRequestSummary = ({ summary, clientAccountName, failed, clientUpload }) => {
    const { t } = useTranslation();
    const file = summary.files.length ? summary.files[0] : null;

    return (
        <div className={style.wizard__summary}>
            {failed && (
                <NotificationAtom type="error">
                    {t('csp.errors.generalSubmit', { what: t('csp.request') })}
                </NotificationAtom>
            )}
            <div className={style.wizard__summary__item}>
                <div className={style.wizard__summary__item__point}>
                    <label>{t('csp.clientMandate')}</label>
                    <p>{clientAccountName}</p>
                </div>
                <div className={style.wizard__summary__item__point}>
                    <label>{t('csp.title')}</label>
                    <p>{summary.title}</p>
                </div>
                {summary.descriptionWhatAndHow && (
                    <div className={style.wizard__summary__item__point}>
                        <label>{t('csp.description')}</label>
                        <p>{summary.descriptionWhatAndHow}</p>
                    </div>
                )}
                {summary.descriptionWhy && (
                    <div className={style.wizard__summary__item__point}>
                        <label>{t('csp.importance')}</label>
                        <p>{summary.descriptionWhy}</p>
                    </div>
                )}
                {!clientUpload && file && (
                    <div className={style.wizard__summary__item__point}>
                        <label>{t('csp.emptyForm')}</label>
                        <p>{file.name ? file.name : file.title}</p>
                    </div>
                )}
                {summary.isUrgent && (
                    <div className={style.wizard__summary__item__point}>
                        <label>{t('csp.urgency')}</label>
                        <p>{t('csp.requested.urgency.placeholder')}</p>
                    </div>
                )}
                {/* {summary.isSensitive && (
                    <div className={style.wizard__summary__item__point}>
                        <label>{t('csp.security')}</label>
                        <p>{t('csp.sensitive')}</p>
                    </div>
                )} */}
            </div>
        </div>
    );
};

AddRequestSummary.defaultProps = {
    summary: {},
    clientAccount: {},
    failed: false,
};

const { object, bool } = PropTypes;

AddRequestSummary.propTypes = {
    summary: object,
    clientAccount: object,
    failed: bool,
};

export default AddRequestSummary;
