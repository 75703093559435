// Base
import React from 'react';
import CloseIcon from 'assets/images/icons/close-icon';
import { useHistory } from 'react-router-dom';

// Style
import style from 'assets/stylesheets/atoms/Buttons/Cancel';

const ButtonCancel = ({ tstId, children, isKYCFlow, clientId }) => {
    const history = useHistory();

    return (
        <button
            onClick={() => {
                isKYCFlow
                    ? (window.location.href = `/ongoing-process/${clientId}`)
                    : // let prevHistory = history.goBack();
                      history.goBack();
                // window.location.href = prevHistory;
            }}
            className={style.button__cancel}
            data-test-id="cancel-wizard">
            <span className="screenreader">Go back</span>
            <CloseIcon />
        </button>
    );
};

export default ButtonCancel;
