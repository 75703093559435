// Base
import React from 'react';
import SearchIcon from 'assets/images/icons/search-icon';

// Style
import style from 'assets/stylesheets/atoms/Inputs/Search';

const InputSearch = ({
    id,
    name,
    value,
    onBlur,
    onFocus,
    disabled,
    onChange,
    placeholder,
    customClassesInput,
    validation,
    ...attributes
}) => {
    const inputValidation = validation | '';
    const classList = ['input', style.input, style.input__search];

    if (inputValidation) classList.push(inputValidation);

    return (
        <form role="search" onSubmit={(e) => e.preventDefault()}>
            <div className={classList.join(' ')}>
                <label htmlFor={id} className={style.input__icon}>
                    <span className="screenreader">Search</span>
                    <SearchIcon />
                </label>
                <input
                    type="search"
                    id={id}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disabled={disabled}
                    onChange={onChange}
                    className={`${style.input__search} ${customClassesInput ? ' ' + customClassesInput : ''}`}
                    data-test-id={'input-search'}
                    {...attributes}
                />
            </div>
        </form>
    );
};

export default InputSearch;
