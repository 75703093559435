import React from 'react';

import style from 'assets/stylesheets/atoms/Buttons/Previous';

import ChevronLeftIcon from 'assets/images/icons/chevron-left';

export default class ButtonPrevious extends React.Component {
    render() {
        const { to, onClick, children, customClasses, modifiers, ...attributes } = this.props;

        const buttonClasses = ['button', style.button, style.button__previous];

        return (
            <div className={'button__container'}>
                <button type="button" {...attributes} onClick={onClick} className={buttonClasses.join(' ')}>
                    {/* <i className={`${style.icon} icon icon--arrow-right`} /> */}
                    <span className={style.margin}>
                        <ChevronLeftIcon />
                    </span>
                    {children}
                </button>
            </div>
        );
    }
}
