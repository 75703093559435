// Base
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Template
import FullPageOverlay from 'components/templates/FullPageOverlay';

// Components
import AddRequestWizardOrganism from 'components/organisms/Wizards/AddRequest';

const ClientRequestPage = ({ auth, step, totalSteps, isEditMode, clientUpload }) => {
    const { t } = useTranslation();
    const subPageTitle = `Step ${step < totalSteps ? step : totalSteps} of ${totalSteps}`;

    return (
        <FullPageOverlay
            authObject={auth}
            subPageTitle={subPageTitle}
            isAdmin={auth.user.adminChoice}
            pageTitle={'Upload new document'}>
            <AddRequestWizardOrganism clientUpload={true} newRequest={true} />
        </FullPageOverlay>
    );
};

const select = ({ auth, addrequest: { step, totalSteps, isEditMode, clientUpload } }) => {
    return { auth, step, totalSteps, isEditMode, clientUpload };
};

const { object, bool } = PropTypes;

ClientRequestPage.propTypes = {
    auth: object,
    addrequest: object,
    isEditMode: bool,
    clientUpload: bool,
};

export default connect(select)(ClientRequestPage);
