// Base
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { useLocation } from 'react-router-dom';
import ButtonAtom from 'components/atoms/Buttons/Button';
import FileIcon from 'assets/images/icons/file';
import DeleteIcon from 'assets/images/icons/delete-icon';
import DownloadIcon from 'assets/images/icons/download-icon';
import StackAtom from 'components/atoms/Stack/Stack';

import style from 'assets/stylesheets/molecules/Lists/FileList';


/**
 * Displays a list of documents with an icon that indicates document type.
 * Document can be downloaded, deleted and the buttons can be visible, not visible or disabled.
 */
export const FileList = ({
    files,
    handleDownloadFile,
    handleRemoveFile,
    canDelete,
    canDownload,
    canDownloadDisabled,
    canDeleteDisabled,
    activated,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <ul className={style.files__list}>
            {files.map((value, index) => (
                <li
                    className={activated === index ? style.files__list__item__selected : style.files__list__item}
                    key={index}>
                    <span className={style.files__list__item__filename}>
                        <FileIcon type={value?.contentType || value?.type} />
                        <span>
                            {location?.pathname === '/document-management' ? (
                                <p className={style.files__documentName}>{value?.name || value?.title}</p>
                            ) : (
                                <p>{value?.name || value?.title}</p>
                            )}
                            {value?.type !== 'DOCUMENT_REQUEST' && location?.pathname === '/document-management' && (
                                <p className={style.files__warning}>
                                    {t('csp.documents.warningMessage', {
                                        num: value.clientIds?.length,
                                    })}
                                </p>
                            )}
                        </span>
                    </span>
                    <StackAtom nowrap justify="flex-end">
                        {canDownload && (
                            <ButtonAtom.Icon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDownloadFile(value.documentFileId);
                                }}
                                buttonStyle="secondary"
                                isDisabled={canDownloadDisabled}
                                icon={<DownloadIcon />}>
                                {t('csp.download')}
                            </ButtonAtom.Icon>
                        )}
                        {canDelete && (
                            <ButtonAtom.Icon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveFile(value);
                                }}
                                buttonStyle="secondary"
                                isDisabled={canDeleteDisabled}
                                icon={<DeleteIcon />}>
                                {t('csp.delete')}
                            </ButtonAtom.Icon>
                        )}
                    </StackAtom>
                </li>
            ))}
        </ul>
    );
};

FileList.defaultProps = {
    files: [],
    handleDownloadFile: () => { },
    handleRemoveFile: () => { },
    getDocumentPreview: () => { },
    canDelete: false,
    canDownload: false,
    canDownloadDisabled: false,
    canDeleteDisabled: false,
};

const { arrayOf, shape, string, bool, func } = PropTypes;

FileList.propTypes = {
    files: arrayOf(
        shape({
            contentType: string,
            type: string,
            name: string,
            title: string,
        })
    ),
    handleDownloadFile: func,
    handleRemoveFile: func,
    canDelete: bool,
    canDownload: bool,
    getDocumentPreview: func,
    canDownloadDisabled: bool,
    canDeleteDisabled: bool,
};

export default FileList;
