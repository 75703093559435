import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DownloadIcon from 'assets/images/icons/download-icon';
import MoreIcon from 'assets/images/icons/more-icon';
import ReadMoreIcon from 'assets/images/icons/readmore-icon'; // @todo: get proper external link icon from Thijs
import ExternalLinkIcon from 'assets/images/icons/readmore-icon'; // @todo: get proper external link icon from Thijs
import ButtonAtom from 'components/atoms/Buttons/Button';
import { Link } from 'react-router-dom';
import useOutsideClick from 'components/_helpers/hooks/useClickOutside';

import style from 'assets/stylesheets/atoms/ActionDropdown/ActionDropdown';

const ICON_MAP = {
    download: DownloadIcon,
    link: ReadMoreIcon,
    externalLink: ExternalLinkIcon,
    button: null,
};

/**
 * Component used for displaying a toggle/download button that shows dropdown with actions
 */

const ActionDropdown = ({
    actions: builtInActions,
    loading,
    toggleType,
    loadingLabel,
    emptyLabel,
    handleOnClick,
    customClass,
    handleDownload,
    handleUserStatus,
    handleEditRoleModal,
    handleResendInvitation,
    retrieveUserActions,
    user: userInfo,
    clientId,
    isActive,
    useActionProps,
}) => {
    const [open, setOpen] = useState(false);
    const [actionsList, setActions] = useState([]);
    const [salesforceContactId, setSalesForceContactId] = useState('');
    const [userId, setUserId] = useState('');
    const $dropdown = useRef();
    useEffect(() => {
        if (isActive)
            setUserId(userInfo?.userId);
        else
            setSalesForceContactId(userInfo?.salesforceContactId);

    }, [userInfo]);

    const getActions = (possibleActions) => {
        return possibleActions.map((value) => {
            switch (value) {
                case 'DEACTIVATE':
                    return {
                        contentId: 'DEACTIVATE',
                        type: 'customAction',
                        categoryName: 'Deactivate',
                        handleCustomAction: () => handleUserStatus({ userId: userInfo.userId, action: value }),
                    };
                case 'RESEND_INVITATION':
                    return {
                        contentId: 'RESEND_INVITATION',
                        type: 'customAction',
                        categoryName: 'Resend',
                        handleCustomAction: () => {
                            handleUserStatus({ userId: userInfo.userId, action: value });
                            handleResendInvitation();
                        },
                    };
                case 'ACTIVATE_WITH_INVITATION':
                    return {
                        contentId: 'ACTIVATE_WITH_INVITATION',
                        type: 'customAction',
                        categoryName: 'Send invitation',
                        handleCustomAction: () =>
                            handleUserStatus({ salesforceContactId: salesforceContactId, action: value }),
                    };
                case 'EDIT_ROLE':
                    return {
                        contentId: 'EDIT_ROLE',
                        type: 'customAction',
                        categoryName: 'Edit role',
                        handleCustomAction: () => handleEditRoleModal(userInfo),
                    };
                case 'VIEW':
                    return {
                        contentId: 'VIEW',
                        type: 'link',
                        categoryName: 'View user',
                        url: `/user/${userInfo.userId}`,
                    };
                default:
                    return null;
            }
        });
    };
    const onClickOutside = () => {
        if (!open) return;
        setOpen(false);
    };

    useOutsideClick($dropdown, onClickOutside);

    const onRootKeyUp = ({ key }) => {
        if (key === 'Enter') {
            setOpen(true);
            return;
        }

        if (key === 'Escape') {
            setOpen(false);
            $dropdown.current.focus();
            return;
        }
    };

    const toggleDropdown = () => {
        if (useActionProps) {
            if (!open) {
                handleOnClick();
            }
            setOpen(!open);
        }
        else if (!open) {
            //fetch the data here
            //before fetching check if the data is already available in State
            if (isActive) {
                const body = {
                    userId: userId,
                    clientAccountId: clientId
                };
                const datacheck = retrieveUserActions(body);
                datacheck.then(response => {
                    let possibleActions = ['EDIT_ROLE', ...response.possibleActions, 'VIEW'];
                    setActions(getActions(possibleActions));
                });
            }
            else {
                setActions(getActions(['ACTIVATE_WITH_INVITATION']));
            }
            handleOnClick();
        }
        setOpen(!open);
    };

    const renderActionRow = (item, type) => {
        return (
            <div className={style.actionDropdown__row}>
                <p>{item.categoryName}</p>
                <span className={style.actionDropdown__icon}>{ICON_MAP[type]}</span>
            </div>
        );
    };
    const actionsToDisplay = useActionProps ? builtInActions : actionsList;

    return (
        <div
            ref={$dropdown}
            onKeyUp={onRootKeyUp}
            aria-haspopup="menu"
            aria-expanded={open}
            className={classNames(style.actionDropdown, customClass)}>
            <ButtonAtom.Icon
                buttonStyle="secondary"
                icon={toggleType === 'download' ? <DownloadIcon /> : <MoreIcon />}
                customClasses={style.actionDropdown__trigger}
                data-open={open}
                onClick={toggleDropdown}>
                Toggle
            </ButtonAtom.Icon>
            <div className={style.actionDropdown__panel} data-open={open}>
                {loading ? (
                    <div className={style.actionDropdown__row}>{loadingLabel}</div>
                ) : (
                    <>
                        {actionsToDisplay?.length > 0 ? (
                            actionsToDisplay?.map((item) => {
                                const type = item.type || 'download';

                                if (type === 'externalLink') {
                                    return (
                                        <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            key={item.contentId}
                                            className={style.actionDropdown__link}>
                                            {renderActionRow(item, type)}
                                        </a>
                                    );
                                } else if (type === 'link') {
                                    return (
                                        <Link to={item.url} key={item.contentId} className={style.actionDropdown__link}>
                                            {renderActionRow(item, type)}
                                        </Link>
                                    );
                                } else if (type === 'customAction') {
                                    return (
                                        <button
                                            key={item.contentId}
                                            className={style.actionDropdown__button}
                                            onClick={() => {
                                                item.handleCustomAction();
                                                toggleDropdown();
                                            }}>
                                            {renderActionRow(item, type)}
                                        </button>
                                    );
                                }

                                return (
                                    <button
                                        key={item.contentId}
                                        className={style.actionDropdown__button}
                                        onClick={() => handleDownload(item)}>
                                        {renderActionRow(item, type)}
                                    </button>
                                );
                            })
                        ) : (
                            <div className={style.actionDropdown__row}>Loading...</div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const { arrayOf, shape, oneOf, string, bool, func } = PropTypes;

ActionDropdown.propTypes = {
    /** shows download icon or seemore icon on the toggle button */
    toggleType: oneOf(['download', 'seemore']),
    loading: bool,
    loadingLabel: string,
    emptyLabel: string,
    /** toggle callback function */
    handleOnClick: func,
    /** general download document function */
    handleDownload: func,
};

ActionDropdown.defaultProps = {
    toggleType: 'download',
    loading: false,
    customClass: '',
    emptyLabel: 'Empty',
    loadingLabel: 'Loading...',
    handleOnClick: () => { },
};

export default ActionDropdown;
