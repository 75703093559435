// Base
import React from 'react';
import PropTypes from 'prop-types';
// Style
import style from 'assets/stylesheets/atoms/Inputs/Textarea';

const Textarea = ({
    required,
    label,
    placeholder,
    customIconClass,
    parentCustomClass,
    hasLabelBefore,
    ...attributes
}) => {
    const textareaClasses = ['button', style.button, style.button__default];
    if (hasLabelBefore) textareaClasses.push(style.input__label__before);

    return (
        <div className={ `input ${ style.input } ${ parentCustomClass } ${ textareaClasses.join(' ') } ` }>
            <label className={ `${ style.input__label } ${style.input__textarea__label}` }>
                <span className={ `input__label__content ${ !hasLabelBefore && 'screenreader' }` }>
                    { label }
                </span>
                { required &&
                        <i className={ `${ customIconClass ? customIconClass : ''} icon icon--star-filled ${ style['icon--star-filled'] }` }>
                            <span>&#42;</span>
                        </i>
                }
            </label>
            <textarea
                { ...attributes }
                data-lpignore={ true }
                placeholder={ placeholder }
                className={ `${ style.input__type }` }
            />
        </div>
    );
};

Textarea.defaultProps = {
    required: false,
    label: '',
    placeholder: '',
    customIconClass: '',
    parentCustomClass: ''
};

const { string, bool } = PropTypes;

Textarea.propTypes = {
    required: bool,
    label: string,
    placeholder: string,
    customIconClass: string,
    parentCustomClass: string,
};

export default Textarea;