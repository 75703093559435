import React from 'react';

import style from 'assets/stylesheets/atoms/Text/HighlightedText';

const HighlightedText = ({ text, searchQuery }) => {
    if(!searchQuery) return text;
    const searchPattern = searchQuery.replace(/[^A-Z0-9]/gim, '');
    const pattern = new RegExp(searchPattern, 'gi');
    const textArray = text?.replace(pattern, str => `|${str}|`).split('|');

    const highlightedText = textArray?.map((chars, i) => {
        const match = chars.localeCompare(searchQuery, 'en', { sensitivity: 'base' })  === 0;
        return match ? <mark className={ style['search-result__query'] } key={ i }>{chars}</mark> : chars;
    });

    return <>{ highlightedText }</>;
};

export default HighlightedText;
