import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { retrieveProcess } from 'services/ongoingProcessApiService';

export default function useOnboardingRedirect() {
    const history = useHistory();
    const location = useLocation();
    const { clientId } = useParams();
    const clientWidgets = useSelector((state) => state.clientWidgets);

    const [isOnboardingChecked, setIsChecked] = useState(false);

    // Fetch the client info when needed
    useEffect(() => {
        setIsChecked(false);
        // Ignore if on select-client page or no-clients
        if (location.pathname === '/select-client' || location.pathname === '/no-clients') {
            setIsChecked(true);
        }

        // Ignore if no enabled widgets data
        if (!clientWidgets.enabledWidgets) return;

        // Re-direct to process page, only if process is enabled as a widget
        if (clientWidgets.enabledWidgets.find((widget) => widget.widgetCode === 'PROCESS')) {
            const getProcess = async (clientId) => {
                const processResponse = await retrieveProcess(clientId, { activeWorkstreamsOnly: true });

                const redirect = processResponse?.data?.type === 'ONBOARDING' ? true : false;
                // Redirect to select page when client info cannot be selected
                if (redirect) {
                    history.push(`/ongoing-process/${clientId}`);
                }
                setIsChecked(true);
            };

            if (clientId) {
                getProcess(clientId);
            }
        } else {
            setIsChecked(true);
        }
    }, [clientId, clientWidgets]);

    return [isOnboardingChecked, setIsChecked];
}
