import React from 'react';

const MoreIcon = () => (
    <svg viewBox="0 0 30 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 13a2 2 0 110 4 2 2 0 010-4zm-6.538 0a2 2 0 110 4 2 2 0 010-4zM9 13a2 2 0 110 4 2 2 0 010-4z"
            fill="currentColor"
        />
    </svg>
);

export default MoreIcon;
