import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { addRequestMetadata, addRequestSummary, updateRequestWizardState, addRequestUpload, UpdateScanFilePostDelete } from 'src/redux/actions';
import { useHistory } from 'react-router-dom';
import { createNotification } from '@/redux/sagas/common/actions';
import routes from 'data/routes.json';
import AddSummaryStepWizardMolecule from 'components/molecules/Wizards/templates/AddSummaryStep';
import AddMetadataStepWizardMolecule from 'components/molecules/Wizards/templates/AddMetadataStep';
import RequestMetadataMolecule from 'components/molecules/Overview/RequestMetadata';
import AddRequestSummaryWizardMolecule from 'components/molecules/Wizards/AddRequest/AddRequestSummary';
import StandardHeadingAtom from 'components/atoms/Headings/StandardHeading';
import { clearRequestUploadLinks } from 'src/redux/actions';

const AddRequestWizard = ({ data, clientId, clientName, isEditMode, clientUpload, correctRequestFileData, correctRequestFileWithKey, malwareData, timeoutError, apiError }) => {
    const [isValidated, setIsValidated] = useState(isEditMode);
    const { step, metadata, requestUploadFailed, requestUploadCompleted, sendNotifications } = data;
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (requestUploadCompleted) {
            // history.push(`document-store/${clientId}`);
            history.goBack();
            dispatch(createNotification({ message: 'Request submitted', type: 'success' }));
        }
    }, [requestUploadCompleted]);

    useEffect(() => {
        dispatch(clearRequestUploadLinks());
    },[]);

    const handleMetadata = (value, key) => {
        let newMetadata = metadata;
        newMetadata[key] = value;
        setIsValidated(!!newMetadata.title.trim());
        dispatch(addRequestMetadata(newMetadata));
    };

    const handleDrop = async (files, fileRemoved = '') => {
        const newMetadata = {
            ...metadata,
            files: files,
        };
        if (fileRemoved) dispatch(UpdateScanFilePostDelete(files));
        dispatch(addRequestMetadata(newMetadata));
    };

    const handleSendNotifications = (data, value) => {
        dispatch(updateRequestWizardState({ sendNotifications: value }));
    };

    const handleUpload = (data, type) => {
        if (clientUpload) {
            dispatch(addRequestUpload({ data, isEditMode, documentId: metadata.documentId, clientUpload }));
        } else {
            const params = {
                sendNotifications: sendNotifications || false,
                title: metadata.title,
                descriptionWhatAndHow: metadata.descriptionWhatAndHow || null,
                descriptionWhy: metadata.descriptionWhy || null,
                clientAccountIds: [clientId],
                files: metadata.files || null,
                // isSensitive: metadata.isSensitive,
                isUrgent: metadata.isUrgent,
            };
            dispatch(addRequestUpload({ params, isEditMode, documentId: metadata.documentId, clientUpload }));
        }
    };

    const handleNextStep = (value) => {
        let action;
        switch (step) {
            case 1:
                action = addRequestSummary(value);
                break;
            default:
                break;
        }

        dispatch(action);
    };

    const handlePreviousStep = (value) => {
        let action;
        switch (step - 1) {
            case 1:
                action = addRequestMetadata(value);
                break;
            default:
                break;
        }

        dispatch(action);
    };

    const handleRetry = () => {
        setIsValidated(false);
        dispatch(addRequestMetadata(metadata));
    };

    switch (step) {
        default:
        case 1:
            return (
                <AddMetadataStepWizardMolecule
                    step={step}
                    data={metadata}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    nextStepText={'See summary'}
                    isValidated={isValidated}
                    clientUpload={clientUpload}
                    correctFiles={correctRequestFileData}
                    malwareData={malwareData}
                    apiError={apiError}
                    timeoutError={timeoutError}>
                    {clientUpload ? (
                        <StandardHeadingAtom
                            heading={'Document details'}
                            subheading={
                                'Use this section to add additional metadata to the documents you want to upload.'
                            }
                        />
                    ) : (
                        <StandardHeadingAtom
                            heading={t('csp.requested.heading')}
                            subheading={t('csp.requested.subheading')}
                        />
                    )}
                    <RequestMetadataMolecule
                        metadata={metadata}
                        clientName={clientName}
                        handleMetadata={handleMetadata}
                        handleDrop={handleDrop}
                        clientUpload={clientUpload}
                        correctFiles={correctRequestFileData}
                        malwareData={malwareData}
                        timeoutError={timeoutError}
                        apiError={apiError}
                    />
                </AddMetadataStepWizardMolecule>
            );
        case 2:
            return (
                <AddSummaryStepWizardMolecule
                    step={step}
                    data={metadata}
                    eventTrigeredFrom="DocumentRequest"
                    handleDocumentUpload={handleUpload}
                    handlePreviousStep={handlePreviousStep}
                    sendNotifications={sendNotifications}
                    handleSendNotifications={handleSendNotifications}
                    handleRetry={handleRetry}
                    failed={requestUploadFailed}
                    clientId={clientId}
                    isEditMode={isEditMode}
                    paramData={data}
                    clientUpload={clientUpload}
                    correctRequestFileWithKey={correctRequestFileWithKey}>
                    <StandardHeadingAtom heading={t('csp.finalCheck')} />
                    <AddRequestSummaryWizardMolecule
                        summary={metadata}
                        clientAccountName={clientName}
                        failed={requestUploadFailed}
                        clientUpload={clientUpload}
                    />
                </AddSummaryStepWizardMolecule>
            );
    }
};

AddRequestWizard.defaultProps = {
    data: {},
    clientName: '',
    clientId: '',
    clientUpload: false,
};

const { string, object, bool } = PropTypes;

AddRequestWizard.propTypes = {
    data: object,
    clientName: string,
    clientId: string,
    isEditMode: bool,
    clientUpload: bool,
};

const select = ({ addrequest, clientAccounts: { currentClient } }) => {
    return {
        data: addrequest,
        isEditMode: addrequest.isEditMode,
        clientName: currentClient?.name,
        clientId: currentClient?.id,
        correctRequestFileData: addrequest.correctRequestFileData,
        correctRequestFileWithKey: addrequest.correctRequestFileWithKey,
        malwareData: addrequest.malwareData,
        apiError: addrequest.apiError,
        timeoutError: addrequest.timeoutError
    };
};

export default connect(select)(AddRequestWizard);
