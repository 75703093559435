/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

export default function removeDuplicates(array) {
    let hashTable = {};

    return array.filter((el) => {
        let key = JSON.stringify(el.name),
            match = Boolean(hashTable[key]);

        return (match ? false : hashTable[key] = true);
    });
}
