// Base
import React from 'react';
// Style
import style from 'assets/stylesheets/atoms/Headings/StandardHeading';

const StandardHeading = ({ heading, subheading, children }) => {
    return(
        <div className={ `${style['standard-heading']} col-11 pl-0`}>
            <h2 className={ style['standard-heading__h'] }>{ heading }</h2>
            { subheading && <p className={ style['standard-heading__body'] }>{ subheading }</p> }
            { children }
        </div>
    );
};

export default StandardHeading;
