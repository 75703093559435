import React from 'react';
import PropTypes from 'prop-types';

import PlaceHolder from 'assets/images/avatar-placeholder.png';

// Style
import style from 'assets/stylesheets/atoms/Images/Avatar';

const Avatar = ({ src, alt, type, size, customClass = '' }) => {
    const avatarType = `avatar--${type}`;
    const avatarSize = `avatar--${size}`;
    return (
        <img
            className={`${style.avatar} ${style[avatarType]} ${style[avatarSize]} ${customClass}`}
            width="40"
            height="40"
            src={src ? src : ''}
            alt={alt}
            loading='lazy'
        />
    );
};

const { string, oneOf } = PropTypes;

Avatar.propTypes = {
    src: string,
    /** Alt text for avatar */
    alt: string,
    /** User avatar is circular, account avatar is squared */
    type: oneOf(['user', 'account']),
    /** Default size is small, tiny is used in ex. awareness bar */
    size: oneOf(['small', 'tiny']),
};

Avatar.defaultProps = {
    src: '',
    alt: 'User Avatar',
    type: 'account',
    size: 'small',
};

export default Avatar;
